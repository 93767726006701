import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.css'
import * as VueGoogleMaps from 'vue2-google-maps';


Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDW9hIj1YK_VvDJuWh2-YGH4172RAohMq0',
    libraries: 'places', // Se você quiser usar a biblioteca de lugares do Google
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
