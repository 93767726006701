<template>
  <div class="covenants-container">
    <h2 class="covenants-title">Convênios</h2>
    <div class="covenants-item" >
        <slider ref="slider" :options="options" style="width:75%;">
            <slideritem class="slider-item" v-for="(item,index) in someList" :key="index" :style="item.style"></slideritem>
            <div slot="loading">loading...</div>
        </slider>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'

import assefaz from "../assets/covenants/assefaz.png";
import associl from "../assets/covenants/associl.png";
import caixa from "../assets/covenants/caixa.jpg";
import copasa from "../assets/covenants/copasa_saude.png";
import dentalpar from "../assets/covenants/dentalpar.jpg";
import divimedicos from "../assets/covenants/divimedicos.png";
import geap from "../assets/covenants/geap.jpg";
import hapvida from "../assets/covenants/hapvida.jpg";
import interodonto from "../assets/covenants/interodonto.png";
import medPrev from "../assets/covenants/med_prev.jpg";
import medclass from "../assets/covenants/medclass.png";
import metlife from "../assets/covenants/metlife.png";
import odontoEmpresas from "../assets/covenants/odonto_empresas.png";
import odontovida from "../assets/covenants/odontovida.jpg";
import petrobras from "../assets/covenants/petrobras.jpg";
import pmmg from "../assets/covenants/pmmg.png";
import portoSeguro from "../assets/covenants/porto_seguro.jpg";
import previMinas from "../assets/covenants/previminas.jpg";
import proSocial from "../assets/covenants/pro_social.png";
import prodent from "../assets/covenants/prodent.jpg";
import redeUnna from "../assets/covenants/rede_unna.jpg";
import risodent from "../assets/covenants/risodent.png";
import sorriso from "../assets/covenants/Sorriso.png";
import trt from "../assets/covenants/trt.jpg";
import unimed from "../assets/covenants/unimed.jpg";
import uniodonto from "../assets/covenants/uniodonto.jpg";
import usiminas from "../assets/covenants/usiminas.jpg";
import valeSaude from "../assets/covenants/vale_saude.jpg";

export default {
  name: "IrodCoventants",

  components: {
    slider,
    slideritem
  },

  data() {
    return {
      someList: [
        {
          style:{
            backgroundImage: `url(${assefaz})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${associl})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${caixa})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${copasa})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${dentalpar})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${divimedicos})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${geap})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${hapvida})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${interodonto})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${medPrev})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${medclass})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${metlife})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${odontoEmpresas})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${odontovida})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${petrobras})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${pmmg})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${portoSeguro})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${previMinas})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${proSocial})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${prodent})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${redeUnna})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${risodent})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${sorriso})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${trt})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${unimed})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${uniodonto})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${usiminas})`,
            'margin-right': '2%'
          }
        },
        {
          style:{
            backgroundImage: `url(${valeSaude})`,
            'margin-right': '2%'
          }
        },
      ],
      options: {
        currentPage: 0,
        tracking: false,
        thresholdDistance: 100,
        thresholdTime: 300,
        infinite: 3,
        slidesToScroll: 3,
        autoplay: '2000',
        loop: true,
        pagination: false,
      }
    };
  }
};
</script>

<style scoped>
.covenants-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 35vh;
}

@media (max-width: 600px) {
  .covenants-container {
    margin-bottom: 15vh;
  }
}

.covenants-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #A7CA6E;
  margin-bottom: 1%;
}

@media (max-width: 600px) {
  .covenants-title {
    font-size: 1.5rem;
  }
}

.covenants-item {
  width:75%;
  margin:20px auto;
  height:400px
}

@media (max-width: 600px) {
  .covenants-item {
    height: 150px;
  }
}

.slider-item {
  width: 23.5%;
  background-size: contain;
  background-position: center;
}
</style>