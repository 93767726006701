<template>
  <div class="section">
    <v-img class="section-img" src="../assets/logo branca.png"></v-img>
  </div>
</template>

<script>
export default {
  name: 'IrodSection',
}
</script>

<style scoped>
.section {
  height: 50vh;
  width: 100%;
  background-color: #A8C976;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-img {
  position: absolute;
  width: 500px;
}

@media (max-width: 600px) {
  .section-img {
    width: 150px;
  }
}
</style>