<template>
  <div 
    class="specialties-container"
    max-width="910"
    >
    <div class="specialties-description">
      <v-list class="card-item">
        <v-list-item
          v-for="specialty in specialties"
          :key="specialty"
          class="card-list-item"
        >
          {{ specialty }}
        </v-list-item>
      </v-list>
    </div>

    <div class="specialties-carrossel">
      <v-carousel
      cycle
      interval="3000"
      hide-delimiters
      class="carrossel-item"
      >
      <v-carousel-item cover>
        <img src="../assets/specialtie 1.png" alt="Specialtie 1" class="carrossel-item-image"/>
      </v-carousel-item>
      <v-carousel-item cover>
        <img src="../assets/specialtie 2.png" alt="Specialtie 2" class="carrossel-item-image"/>
      </v-carousel-item>
      <v-carousel-item cover>
        <img src="../assets/specialtie 4.png" alt="Specialtie 4" class="carrossel-item-image"/>
      </v-carousel-item>
      <v-carousel-item cover>
        <img src="../assets/specialtie 5.png" alt="Specialtie 5" class="carrossel-item-image"/>
      </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>

export default {

  name: 'IrodSpecialties',

  data() {
    return {
      show: false,
      specialties: [
        "Raio X Panorâmico",
        "Telerradiografia",
        "Periapicais",
        "Interproximais",
        "Levantamento Periapical",
        "Radiografia de Mão e Punho",
        "Impressão 3D",
        "Tomógrafo de Alta Resolução",
        "Radiografias Digitais com Laudos e Análises",
        "Modelos em Gesso", 
        "Fotografias Intra-Orais e Extra-Orais",
        "Escaneamento intra-oral",
        "Planejamento de cirurgia guiada",
        "Documentações ortodônticas",
      ],
    };
  },
}
</script>

<style scoped>
.specialties-container {
  background-color: #1A222D;
  display: flex;
  justify-content: space-evenly;
  margin-top: 40vh;
  height: 730px;
  align-items: center;
  margin-bottom: 40vh;
}

@media (max-width: 600px) {
  .specialties-container {
    margin-bottom: 15vh;
  }
}

.specialties-description{
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
  .specialties-description {
    margin-left: 1px !important;
  }
}

.card-item{
  background-color: unset !important;
  width: fit-content;
}

@media (max-width: 600px) {
  .card-item {
    width: max-content !important;
  }
}

.card-list-item{
  color: white !important;
  font-size: 1vw !important;
}

@media (max-width: 600px) {
  .card-list-item {
    font-size: 8px !important;
    min-height: 35px;
  }
}

.specialties-container h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.specialties-carrossel{
  width: 900px;
  padding-right: 100px;
}

@media screen and (max-width: 1366px) {
  .specialties-carrossel{
    width: 700px;
    padding-right: 50px;
  }
}

@media (max-width: 600px) {
  .specialties-carrossel{
    width: 230px;
    padding-right: 20px;
  }

  .carrossel-item{
    border-radius: 60px 0 60px 0 !important;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    height: 250px !important;
  }

  .carrossel-item-image{
    height: 50% !important;
  }
}


.carrossel-item{
  border-radius: 150px 0 150px 0;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}

.carrossel-item-image{
    height: 140%;
    width: 100%;
  }
</style>