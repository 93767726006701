<template>
  <div>
    <h2 class="map-title">Onde Estamos</h2>
    <div class="map-content">
      <div class="map-container">
        <GmapMap
          :center="location"
          :zoom="17"
          map-type-id="roadmap"
          style="width: 100%; height: 100%;"
        >
          <GmapMarker :position="location" />
        </GmapMap>
        <div class="location-details">
          <h3>Irod Imagens - Unidade Rio de Janeiro</h3>
          <p>R. Rio de Janeiro, 321 - Centro
          Divinópolis - MG
          35500-009
          Brasil</p>
        </div>
      </div>
      <div class="map-container">
        <GmapMap
          :center="locationPioneiro"
          :zoom="17"
          map-type-id="roadmap"
          style="width: 100%; height: 100%;"
        >
          <GmapMarker :position="locationPioneiro" />
        </GmapMap>
        <div class="location-details">
          <h3>Irod Imagens - Unidade Pioneiro</h3>
          <p>R. Minas Gerais, 655 sala 805 Edificio Pioneiro
          Centro Divinopolis-MG
          35500-007
          Brasil</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "WhereAreWe",

  data() {
    return {
      location: {
        lat: -20.142973249946937,
        lng: -44.88840541809812,
      },
      locationPioneiro: {
        lat: -20.14444,
        lng: -44.89170,
      },
    };
  },
}
</script>

<style scoped>
.map-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15vh;
  justify-content: center;
}

@media (max-width: 850px) {
  .map-content {
    flex-direction: column;
  }
}
.map-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #A7CA6E;
  margin-top: 35vh;
  margin-bottom: 3%;
}

@media (max-width: 600px) {
    .map-title {
    font-size: 1.5rem;
  }
}

.map-container {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 10px;
}

@media (max-width: 600px) {
    .map-container {
    width: 330px;
    height: 330px;
  }
}

.location-details {
  width: 200px;
  position: absolute;
  top: 53px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .location-details{
    width: 125px;
  }

  .location-details h3{
    font-size: 15px;
  }

  .location-details p{
    font-size: 10px;
  }
}
</style>