<template>
  <div id="container">
    <v-img
      class="img"
      width="45%"
      src="../assets/logo verde.png"
    ></v-img>
  </div>
</template>

<script>
  export default {
    name: 'HomeDescription',

    data() {
      return {
      };
    },
  }
</script>

<style scoped>
  #container {
    height: 40vw;
    width: 100%;
    padding: 0 !important;
    margin: 0;
    background: rgb(26,34,45);
    margin-bottom: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px){
    #container {
      height: 50vh;
      margin-bottom: 40vw;
    }

    .img {
      width: 50% !important;
    }
  }

  .img {
    position: absolute;
  }
</style>