<template>
  <div>
    <v-app-bar
      color="primary"
      app
      class="toolbar"
      flat
      v-if="!resize"
    >
      <v-toolbar-title class="irod-logo">
        <img src="./assets/logo preta.png" alt="Logo" width="10px" height="10px"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text @click="navigateTo('homeDescription')">Home</v-btn>
      <v-btn text @click="navigateTo('aboutUs')">Quem somos</v-btn>
      <v-btn text @click="navigateTo('irodSpecialties')">Especialidades</v-btn>
      <v-btn text @click="navigateTo('irodCovenants')">Convênios</v-btn>
      <v-btn text @click="navigateTo('whereAreWe')">Onde Estamos</v-btn>
      <v-btn text @click="navigateTo('irodContact')">Fale conosco</v-btn>
      <v-btn text class="exams-link"><a href="/exams">Exames</a></v-btn>
    </v-app-bar>

    <v-app-bar
      color="primary"
      app
      class="toolbar"
      flat
      v-if="resize">
      <v-app-bar-nav-icon variant="text" @click.stop="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="irod-logo">
        <img src="./assets/logo preta.png" alt="Logo" width="10px" height="10px"/>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      ref="myDrawer"
      v-model="drawer"
      absolute
      class="drawer-container"
      v-if="resize"
    >
      <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.text"
            link
            :to="{ name: item.route }"
            class="drawer-item"
          >
            <v-list-item-title class="drawer-item-title">{{ item.text }}</v-list-item-title>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      activeSection: '',
      drawer: false,
      items: [
        { text: 'Home', route: 'homeDescription' },
        { text: 'Quem somos', route: 'aboutUs' },
        { text: 'Especialidades', route: 'irodSpecialties' },
        { text: 'Convênios', route: 'irodCovenants' },
        { text: 'Onde Estamos', route: 'whereAreWe' },
        { text: 'Fale conosco', route: 'irodContact' },
        { text: 'Exames', route: 'irodContact' }
      ],
    };
  },

  methods: {
    setActiveSection(section) {
      this.activeSection = section;
    },

    navigateTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName, params: { _hash: Date.now() } });
      }
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$nextTick(() => {
          this.$refs.myDrawer.$el.style.top = this.drawerTop();
        });
      }
    },

    drawerTop() {
      return `${window.scrollY}px`;
    },
  },

  computed: {
    resize() {
      return window.innerWidth < 1111;
    },
  },
};
</script>

<style scoped>
* {
  font-family: 'Roboto';
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

img {
  height: 100%;
  width: 100%;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #A8C976 !important;
}

.toolbar ::v-deep .v-toolbar__content {
  justify-content: space-around;
}

.irod-logo {
  height: 100%;
  padding: 0;
  margin: 0;
}

.drawer-container {
  z-index: 9 !important;
  background-color: #A8C976 !important;
}

.drawer-item {
  color: #A8C976 !important;
}

.drawer-item-title {
  color: black !important;
  font-weight: 500 !important;
}

.exams-link a{
  text-decoration: none !important;
  color: black;
}
</style>

<style>
::-webkit-scrollbar {
  width: 12px;
  background-color: #1A222D;
}

::-webkit-scrollbar-thumb {
  background-color: #A8C976;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7FA35A;
}
</style>
