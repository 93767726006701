<template>
  <div>
    <v-container fluid class="d-flex justify-center align-center">
      <v-container fluid style="opacity: 0.5;">
        <v-row>
          <v-col cols="12" sm="6" md="4" v-for="(image, index) in images" :key="index" class="mobile-col">
            <v-img class="aboutUs-img" cover :src="image.src" :alt="image.alt" :height="image.height"></v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-card width="570px" height="450px" class="about-us-card" :class="{ 'scale-in-center': aboutUsTop }">
        <v-card-text class="about-us-title">
          Quem somos
        </v-card-text>
        <v-card-text class="about-us-description">
          <p>A IROD existe desde 1993, tendo sido sempre referência na área de Radiologia Odontológica. Nosso maior objetivo: a busca contínua pela qualidade e inovação.
          Somos os pioneiros em radiologia digital, tomografia Cone Beam e escaneamento intraoral no Centro-Oeste mineiro. Oferecemos todos os recursos disponíveis para diagnóstico e planejamento na área de imaginologia odontológica, com um atendimento humanizado de qualidade.
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import image1 from '../assets/aboutUs-1.png';
import image2 from '../assets/aboutUs-2.png';
import image3 from '../assets/aboutUs-3.png';
import image4 from '../assets/aboutUs-4.png';
import image5 from '../assets/aboutUs-5.png';
import image6 from '../assets/aboutUs-6.png';

export default {
  name: 'AboutUs',
  data() {
    return {
      isFaded: false,
      images: [
        {
          src: image1,
          alt: 'Imagem 1',
          width: 500,
          height: 400
        },
        {
          src: image2,
          alt: 'Imagem 2',
          width: 500,
          height: 400
        },
        {
          src: image3,
          alt: 'Imagem 3',
          width: 500,
          height: 400
        },
        {
          src: image4,
          alt: 'Imagem 4',
          width: 500,
          height: 400
        },
        {
          src: image5,
          alt: 'Imagem 5',
          width: 500,
          height: 400
        },
        {
          src: image6,
          alt: 'Imagem 6',
          width: 500,
          height: 400
        }
      ],
    };
  },

  props: {
    aboutUsTop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.aboutUs-img{
  border: solid 5px #8eb649 !important;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px #8eb649;
}

.about-us-card{
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #8eb649 !important;
  border-radius: 20px !important;
  border: white solid 5px !important;
  box-shadow: 0px 0px 10px 0px black;
  padding: 20px;
  margin: 20px;
}
.about-us-title{
  display: flex;
  justify-content: center;
  font-family: roboto;
  font-size: 40px;
  font-weight: bold;
  color: #fcfcfc !important;
  width: auto;
  height: auto;
}

.about-us-description{
  font-family: roboto;
  text-align: justify;
  font-size: 20px;
  font-weight: bold;
  color: #fcfcfc !important;
  width: auto;
  height: auto;
}

.scale-in-center {
	-webkit-animation: scale-in-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .aboutUs-img {
    width: 100%;
    max-width: 100%;
    height: 95% !important;
  }

  .about-us-card {
    width: 70% !important;
    height: 6% !important;
  }

  .about-us-description {
    width: 103%;
  }

  .about-us-description p{
    font-size: 45%;
    text-align: justify;
  }

  .about-us-title {
    font-size: 100%;
    height: inherit;
  }

  .mobile-col {
    width: 50%; /* Redimensiona a largura da imagem para preencher a largura da tela */
    max-width: 50%; /* Define a largura máxima da imagem */
  }
}
</style>