import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeDescription',
    component: HomeView,
    props: { activeSection: 'homeDescription' },
  },
  {
    path: '/',
    name: 'aboutUs',
    component: HomeView,
    props: { activeSection: 'aboutUs' },
  },
  {
    path: '/',
    name: 'irodSpecialties',
    component: HomeView,
    props: { activeSection: 'irodSpecialties' },
  },
  {
    path: '/',
    name: 'irodCovenants',
    component: HomeView,
    props: { activeSection: 'irodCovenants' },
  },
  {
    path: '/',
    name: 'whereAreWe',
    component: HomeView,
    props: { activeSection: 'whereAreWe' },
  },
  {
    path: '/',
    name: 'irodContact',
    component: HomeView,
    props: { activeSection: 'irodContact' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
