<template>
  <v-app v-scroll="handleScroll">
    <v-main>
      <home-description ref="homeDescription" />
      <about-us ref="aboutUs" :aboutUsTop="aboutUsTop"/>
      <irod-specialties ref="irodSpecialties" />
      <irod-covenants ref="irodCovenants"/>
      <irod-section />
      <where-are-we ref="whereAreWe" />
      <irod-contact ref="irodContact"/>
      <irod-footer />
      <whats-app-button />
    </v-main>
  </v-app>
</template>

<script>
  import HomeDescription from '@/components/HomeDescription.vue'
  import AboutUs from '@/components/AboutUs.vue'
  import IrodSpecialties from '@/components/IrodSpecialties.vue'
  import IrodFooter from '@/components/IrodFooter.vue'
  import WhereAreWe from '@/components/WhereAreWe.vue'
  import IrodContact from '@/components/IrodContact.vue'
  import IrodCovenants from '@/components/IrodCovenants.vue'
  import IrodSection from '@/components/IrodSection.vue'
  import WhatsAppButton from '@/components/WhatsAppButton.vue'

  export default {
    name: 'HomePage',

    components: {
      HomeDescription,
      AboutUs,
      IrodSpecialties,
      IrodFooter,
      WhereAreWe,
      IrodContact,
      IrodCovenants,
      IrodSection,
      WhatsAppButton,
    },

    props: {
      activeSection: {
        type: String,
        default: 'homeDescription',
      },
    },

    data() {
      return {
        aboutUsTop: false,
      }
    },

    watch: {
      activeSection(newValue) {
        return this.$refs[newValue].$el.scrollIntoView({ behavior: 'smooth' });
      },
  },

    methods: {
      handleScroll() {
        const windowHeight = window.innerHeight;
        const aboutUsRect = this.$refs.aboutUs.$el.getBoundingClientRect();
        const irodSpecialtiesRect = this.$refs.irodSpecialties.$el.getBoundingClientRect();

        const aboutUsPosition = aboutUsRect.top;
        const irodSpecialtiesPosition = irodSpecialtiesRect.top;

        const scrollPosition = window.scrollY + windowHeight;

        if (scrollPosition >= aboutUsPosition) {
          this.aboutUsTop = true;
        } else {
          this.aboutUsTop = false;
        }

        if (scrollPosition >= irodSpecialtiesPosition) {
          // Alterações desejadas no componente IrodSpecialties
        }
      },
    }
  }
</script>

<style scoped>
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

img {
  height: 100%;
  width: 100%;
}


</style>
