<template>
  <v-footer class="irod-footer">
      <div class="media-icons">
      <v-btn class="mx-4 black--text" icon>
        <a href="https://www.instagram.com/iroddivinopolis/" target="_blank" class="black--text">
          <img src="../assets/social_medias/instagram.png" alt="Instagram" width="24px" />
        </a>
      </v-btn>

      <v-btn class="mx-4 black--text" icon>
        <a href="https://www.facebook.com/slicedivinopolismg/" target="_blank" class="black--text">
          <img src="../assets/social_medias/facebook.png" alt="Facebook" width="24px" />
        </a>
      </v-btn>
      </div>
      <v-card-text class="black--text pt-0 footer-description">
        IROD: <br> Para o dentista, o melhor diagnóstico; para o paciente, atendimento diferenciado.
      </v-card-text>
      <v-divider></v-divider>
      <div class="black--text footer-data">
        {{ new Date().getFullYear() }} - <strong>IROD</strong>
      </div>
  </v-footer>
</template>

<script>
export default {
  
    name: 'IrodFooter',
  
    data() {
      return {
        icons: [
          'mdi-facebook',
          'mdi-instagram',
        ],
      };
    },
  
    methods: {
      getLink(icon) {
        if (icon === 'mdi-instagram') {
          return 'https://www.instagram.com/iroddivinopolis/';
        } else if (icon === 'mdi-facebook') {
          return 'https://www.facebook.com/slicedivinopolismg/';
        }
      }
    },
}
</script>

<style>
.irod-footer{
  background-color: #A8C976 !important;
  flex-direction: column;
}

.media-icons{
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-description{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large !important;
}

.footer-data{
  text-align: center;
  margin-top: 10px;
}
</style>