<template>
  <div class="contact-container">
    <p class="contact-description">Para mais informações e orçamentos <a href="/contact">clique aqui</a> e seja redirecionado para a nossa página de contato.</p>
    <br>
    <p class="contact-description">Para olhar os seus exames, <a href="/exams">clique aqui</a></p>
  </div>
</template>

<script>
export default {
  name: 'IrodContact',
}
</script>

<style scoped>
.contact-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #1A222D;
}

.contact-description {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 25%;
}

@media (max-width: 600px) {
  .contact-description {
    width: 65%;
  }
}

p a {
  color: #A8C976;
  text-decoration: none;
}
</style>